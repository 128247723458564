<template>
    <div>
        <b-container style="margin-top: 40px; margin-left: 10px;  max-width: 50%;">

            <h2>FECHAMENTO EXAMES CONSOLIDADO</h2>
            <p align="left">
                <b-form @submit.prevent="save">
                    <b-row>
                        <b-col>
                            <b-form-group id="input-group-2" label="Data Inicio" label-for="input-2">
                                <date-picker v-model="formdata.datainicio" :config="options">
                                </date-picker>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group id="input-group-2" label="Data Fim" label-for="input-3">
                                <date-picker v-model="formdata.datafim" :config="options"></date-picker>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <br>

                    <b-row>
                        <b-col>
                            <p align="left" class="pesquisa_select">
                                <b-button @click="createItem" pill variant="success" size="sm">Listar
                                    Relatório</b-button>
                            </p>
                        </b-col>
                    </b-row>
                </b-form>
            </p>

        </b-container>
        <!-- Info modal -->
        <b-modal v-model="modalShow" hide-footer size="xl">

            <b-button type="button" scale="2" variant="outline-success" v-on:click="download"><b>Excel</b> <b-icon
                    icon="file-excel"></b-icon></b-button>
            <b-button type="button" scale="2" variant="outline" v-on:click="print"><b> Imprimir</b> <b-icon
                    icon="printer"></b-icon></b-button>
            <b-form-input id="input-2" hidden="true" v-model="sessaoModal"></b-form-input>
             <div class="border-0" id="input-00">{{ periodoRelatorio }}</div>


            <div :id="sessaoModal">
                <br />           
                <div class="divTableHeading">
                    <div class="divTableRow">
                        <div class="divTableHead1">EXAME</div>
                        <div class="divTableHead">QTD</div>
                        <div class="divTableHead10">  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;      TOTAL               </div>
                    </div>
                </div>
                <div class="divTable" v-for="item in dadosAsoTeste" v-bind:key="item.id">
                    <div class="divTableFoot tableFootStyle">
                        <div class="divTableRow">
                            <div class="divTableCell1">{{ item.name }} </div>
                            <div class="divTableCell2">{{ item.qtdExames }} </div>
                            <div class="divTableCell"> {{ convertNumberToReal(item.somatoriaExames) }}  </div>
                        </div>
                    </div>
                </div>

                
           
                <br />
                <br />
           
            </div>


        </b-modal>
    </div>
</template>

<script>
const moment = require("moment");
import { http } from '@/plugins/axios'
import maskMixins from '@/helpers/mixins/mask'
import maskMixins2 from '@/helpers/mixins/mask2'
import * as XLSX from 'xlsx'

export default {
    mixins: [maskMixins, maskMixins2],
    data() {
        return {
            options: {
                format: 'DD/MM/YYYY',
                useCurrent: false,
                locale: moment.locale('pt-br')
            },
            isBusy: false,
            perPage: '',
            currentPage: 1,
            filter: null,
            filter2: null,
            toolbarOptions: ['Print'],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            fields: [
                {
                    key: 'index',
                    label: 'indice',
                    visible: true,
                    sortable: false
                },
                {
                    key: 'aso.id',
                    label: 'id',
                    visible: false,
                    sortable: true,
                    thStyle: { width: "60%" },
                    tdClass: 'fonteLinhasLeft'
                }
            ],
            empresas: [],
            listUsers: '',
            listTipoPagamento: '',
            dadosAso: [],
            dadosAsoTeste: [],
            dadosAsoExcluidas: [],
            dadosAsoEditadas: [],
            totalExames: 0,
            medicosAtivos: '',
            dataRecibo:'',
            nomeMedico: '',
            crmMedico:'',
            tipoPagamento: '',
            usuarioPesquisa: '',
            periodoRelatorio: '',
            tableRelatorioRecepcao: {},
            formdata: {},
            sessaoModal: 'print',
            modalShow: false,
            editedIndex: -1,
            empresafora: '',
            list_Exames: []

        }
    },

    mounted() {
      //  this.buscaMedicosAtivos(),
            setTimeout(() => {// aguarda com spinner antes da pesquisa aparecer na pesquisa inicial
                this.isBusy = false
            }, 2.0 * 2000)
    },
    computed: {

        visibleFields() {
            return this.fields.filter(field => field.visible)
        },

        visibleFields2() {
            return this.fields2.filter(field => field.visible)
        },
        visibleFields3() {
            return this.fields3.filter(field => field.visible)
        },
        rows() {
            return this.dadosAso.length
        },
    },
    methods: {

        calculototal(obj) {
            //  this.totalExames += Number(valor)

            var soma = 0
            obj.forEach(function (objeto) {
                soma += Number(objeto.somatoriaExames)
            })
            return soma
        },

        calculototalOcupacionais(obj) {

            var soma = 0
            obj.forEach(function (objeto) {
                soma += Number(objeto.qtdExamesOcupacionais);
            })
            return soma

        },



        print() {
            const modal = document.getElementById(this.sessaoModal)
            const cloned = modal.cloneNode(true)
            var section = document.getElementById('print')

            if (!section) {

                section = document.createElement("div")
                section.id = "print"
                document.body.appendChild(section)

            }

            section.innerHTML = "";
            section.appendChild(cloned);
            window.print();
        },



        async ActionFindFechamentoMedico(datainicio, datafim) {


            this.periodoRelatorio = 'Período: ' + datainicio + ' a ' + datafim;
        

            datainicio = this.formatarDataParaBanco(datainicio);
            datafim = this.formatarDataParaBanco(datafim);

           

            //BUSCAS OS EXAMES CADASTRADASTRADOS PARA FECHAMENTO         
            await http.get('exame/selecionando/localrealizacao').then(resp => {

                var list_DadosExames = [];
                (resp.data).forEach(function (item) {

                    //BUSCA OS EXAMES REALIZADOS pelo tipo aso
                    http.get('examesaso/listexames/' + datainicio + '/' + datafim+'/'+item.id).then(resp2 => {
                        if (resp2) {

                            var contaExames = 0;
                            var somaExames = 0;
                            (resp2.data).forEach(function (item2) {
                                  contaExames = contaExames + 1 
                                  somaExames = somaExames + parseFloat(item2.valorexame)
                            })

                            item.somatoriaExames = somaExames
                            item.qtdExames = contaExames

                            list_DadosExames.push(item); 
                        }

                        console.log(list_DadosExames)
                    })
                 
                })
                this.dadosAsoTeste = list_DadosExames;


            })
           
        },


        download: function () {
            const data = XLSX.utils.json_to_sheet(this.dadosAso)
            const wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, data, 'data')
            XLSX.writeFile(wb, 'table_relatorios.xlsx')
        },

        createItem() {

            //GERA UMA STRING ALEATORIA PARA O RELATORIO 
            var gerarString = this.formdata.datainicio + this.formdata.datafim + this.formdata.medico_id;
            this.sessaoModal = this.geraStringAleatoria(20, gerarString)

            this.ActionFindFechamentoMedico(this.formdata.datainicio, this.formdata.datafim, this.formdata.medico_id)
            this.modalShow = true;
            this.editedIndex = -1;
        },

        close() {
            this.modalShow = false;
            setTimeout(() => {
                this.formdata = {}
                this.editedIndex = -1;
            }, 300);
        },

        geraStringAleatoria(tamanho, caracteres) {

            var stringAleatoria = '';
            for (var i = 0; i < tamanho; i++) {
                stringAleatoria += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
            }
            return stringAleatoria;
        },


        mascaraCnpj(value) {
            var mascara = (`${value}`).replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
            return mascara;
        },

        formatarData: function (value) {
            if (value === null) { return null }
            return new Date(value).toLocaleString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' })
        },

        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        }
    }
}
</script>
<style>
.fonteLinhas {
    font-size: 10px;
    text-align: center
}

.fonteLinhasLeft {
    font-size: 10px;
}

.fonteLinhasLeft2 {
    font-size: 16px;

}


.select-selected {
    border-color: black;
    border: 6px solid;
}

.select-selected.select-arrow-active:after {
    border-color: black;
    top: 7px;
}

.select-items div,
.select-selected {
    color: black;
    padding: 8px 16px;
    border: 1px solid;
    border-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.select-items {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
}

.retiraBorda {
    outline: none;
}

.select-hide {
    display: none;
}

.pesquisa_select {

    position: relative;
    margin-top: 20px;

}

.divTable {
    display: table;
    width: 100%;
    margin-bottom: 20px;
}

.divTableRow {
    display: table-row;
}

.divTableHeading {
    background-color: #EEE;
    display: table-header-group;
}

.divTableCell,
.divTableHead {
    border: 1px solid #999999;
    display: table-cell;
    padding: 3px 10px;
}

.divTableCell1,
.divTableHead {
    border: 1px solid #999999;
    display: table-cell;
    width: 450px;
    padding: 3px 10px;
}

.divTableCell2,
.divTableHead {
    border: 1px solid #999999;
    display: table-cell;
    width: 150px;
    padding: 3px 10px;
}


.divTableHead1 {
    border: 1px solid #999999;
    display: table-cell;
    width: 450px;
    padding: 3px 10px;
}

.divTableHead3 {
    border: 1px solid #999999;
    display: table-cell;
    width: 100px;
    padding: 3px 10px;
}

.divTableHeading {
    background-color: #EEE;
    display: table-header-group;
    font-weight: bold;
}

.divTableHead {
    background-color: rgba(0, 0, 0, 0.1);
    font-weight: bold;
    
}
.divTableHead10 {
    background-color: rgba(0, 0, 0, 0.1);
    font-weight: bold;
    width: 500px;
}

.divTableFoot {
    background-color: #EEE;
    display: table-footer-group;
    font-weight: bold;
}

.divTableBody {
    display: table-row-group;
}







.select-items div:hover,
.same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
}

@mixin flex-center($columns: false) {
    display: flex;
    align-items: center;
    justify-content: center;

    @if $columns {
        flex-direction: column;
    }
}


@media screen {
    #print {
        display: none;
    }
}

@media print {
    body * {
        visibility: hidden;
    }

    #print,
    #print * {
        visibility: visible;
    }

    #print {
        position: absolute;
        left: 0;
        top: 0;
    }

}
</style>