<template>
  <div>
    <b-container style="margin-top: 40px; margin-left: 10px;  max-width: 50%;">
      <p align="left">
        <b-form @submit.prevent="save">
          <b-row>
            <b-col>
              <b-form-group id="input-group-2" label="Data Inicio" label-for="input-2">
                <date-picker v-model="formdata.datainicio" :config="options">
                </date-picker>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="input-group-2" label="Data Fim" label-for="input-3">
                <date-picker v-model="formdata.datafim" :config="options"></date-picker>
              </b-form-group>
            </b-col>
          </b-row>
          <br>

          <b-form-group class="mb-3" label="Empresa de Fora:">
            <b-form-select id="empresafora" name="empresafora" v-model="empresafora">
              <template #first>
                <b-form-select-option :value="null">Selecione abaixo</b-form-select-option>
              </template>
              <b-form-select-option v-for="(item, index) in empresasfora" v-bind:key="index.id" v-bind:value="item.id">
                {{ item.nome }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>

          <br>
          <b-form-group class="mb-12" label="Empresa:">
            <v-select :options="empresas" :reduce="(option) => option.id" label="nome"
              v-model="formdata.empresa_id"></v-select>
          </b-form-group>
          <br>
          <b-row>
            <b-col cols="3" sm="3">
              <b-form-group id="input-group-2" label="Tipo Pagamento:" label-for="input-2">
                <b-form-select id="tipopagamento" name="tipopagamento" v-model="formdata.tipopagamento_id">
                  <template #first>
                    <b-form-select-option :value="null" disabled>Selecione o Tipo Pagamento</b-form-select-option>
                  </template>
                  <b-form-select-option v-for="(item, index) in listTipoPagamento" v-bind:key="index.id"
                    v-bind:value="item.id">
                    {{ item.descricao }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <br>
          <b-row>
            <b-col cols="3" sm="3">
              <b-form-group id="input-group-2" label="Usuario:" label-for="input-2">
                <b-form-select id="usuario" name="usuario" v-model="formdata.user_id">
                  <template #first>
                    <b-form-select-option :value="null" disabled>Selecione o Tipo Pagamento</b-form-select-option>
                  </template>
                  <b-form-select-option v-for="(item, index) in listUsers" v-bind:key="index.id" v-bind:value="item.id">
                    {{ item.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <p align="left" class="pesquisa_select">
                <b-button @click="createItem" pill variant="success" size="sm">Listar Relatório</b-button>
              </p>
            </b-col>
         
          </b-row>
        </b-form>
      </p>

    </b-container>
    <!-- Info modal -->
    <b-modal v-model="modalShow" hide-footer size="xl">

      <b-button type="button" scale="2" variant="outline-success" v-on:click="download"><b>Excel</b> <b-icon
          icon="file-excel"></b-icon></b-button>
      <b-button type="button" scale="2" variant="outline" v-on:click="print"><b> Imprimir</b> <b-icon
          icon="printer"></b-icon></b-button>
      <b-form-input id="input-2" hidden="true" v-model="sessaoModal"></b-form-input>



      <b-table striped hover responsive :id="sessaoModal" :busy="isBusy" :items="dadosAso" :filter="filter"
        :fields="visibleFields" :per-page="perPage" :current-page="currentPage" :aria-controls="sessaoModal" small>
        <template #cell(index)="data">{{ data.index + 1 }}</template>

        <template v-slot:custom-foot>
          <b-tr>
            <b-th variant="primary" colspan="4">
              <b-form-input class="border-0" id="input-00" v-model="periodoRelatorio"></b-form-input>
            </b-th>
          </b-tr>
          <b-tr>
            <b-th variant="primary" colspan="4">
              <b-form-input class="border-0" id="input-00" v-model="totalExames"></b-form-input>
            </b-th>
          </b-tr>
          <b-tr>
            <b-th variant="primary" colspan="4">
              <b-form-input class="border-0" id="input-00" v-model="usuarioPesquisa"></b-form-input>
            </b-th>
          </b-tr>
          <b-tr>
            <b-th variant="primary" colspan="4">
              <b-form-input class="border-0" id="input-00" v-model="tipoPagamento"></b-form-input>
            </b-th>
          </b-tr>

          <b-tr>
            <b-th variant="primary" colspan="4">
              <b-form-input class="border-0" id="input-00" v-model="nomeEmpresa"></b-form-input>
            </b-th>
          </b-tr>

        </template>

      </b-table>

    </b-modal>
  </div>
</template>

<script>
const moment = require("moment");
import { http } from '@/plugins/axios'
import maskMixins from '@/helpers/mixins/mask'
import maskMixins2 from '@/helpers/mixins/mask2'
import * as XLSX from 'xlsx'

export default {
        mixins:[maskMixins, maskMixins2], 
        data () {
          return {
             options: {
                        format: 'DD/MM/YYYY',
                        useCurrent: false,
                        locale: moment.locale('pt-br')
                      },
              isBusy: false,
              perPage: '',
              currentPage: 1,
              filter: null,
              filter2: null,
              toolbarOptions: ['Print'],
              infoModal: {
                            id: 'info-modal',
                            title: '',
                            content: ''
                         },
              fields: [
                 {
                    key: 'index',
                    label: 'indice',
                    visible: true,
                    sortable: false
                  
                  },
                  {
                        key: 'aso.id',
                        label: 'id',
                        visible: false,
                        sortable: true,
                        thStyle: { width: "60%" },
                        tdClass: 'fonteLinhasLeft'
                   },
                  {
                        key: 'aso.codigoaso',
                        label: 'ASO',
                        visible: true,
                        sortable: true,
                        thStyle: { width: "5%" }

                   },
                   {
                        key: 'aso.paciente.nome',
                        label: 'Paciente',
                        sortable: true,
                        visible: true,
                        thStyle: { width: "25%" },
                        tdClass: 'fonteLinhasLeft'
                   },
                   {
                        key: 'aso.paciente.cpf',
                        label: 'Cpf',
                        sortable: true,
                        visible: true,
                        thStyle: { width: "8%" },
                        tdClass: 'fonteLinhasLeft'
                   },
                   {
                        key: 'aso.tipoaso.descricao',
                        label: 'ASO',
                        visible: true,
                        sortable: true,
                        thStyle: { width: "7%" },
                        tdClass: 'fonteLinhasLeft'
                   },
                   {
                        key: 'exame.name',
                        label: 'Exame',
                        visible: true,
                        sortable: true,
                        thStyle: { width: "20%" },
                        tdClass: 'fonteLinhasLeft'
                    },
                   {
                        key: 'aso.empresa.nome',
                        label: 'Empresa',
                        sortable: true,
                        visible: true,
                        thStyle: { width: "30%" },
                        tdClass: 'fonteLinhasLeft'
                      },
                      {
                        key: 'aso.created_at',
                        label: 'Data',
                        sortable: true,
                        visible: true,
                        thStyle: { width: "10%" },
                        tdClass: 'fonteLinhasLeft'
                      },
                      {
                        key: 'valorexame',
                        label: 'Valor',
                        sortable: true,
                        visible: true,
                        thStyle: { width: "5%" },
                        tdClass: 'fonteLinhasLeft',
                        formatter: 'convertNumberToReal'
                      },
                      {
                        key: 'aso.tipopagamento.descricao',
                        label: 'Tipo Pgto',
                        sortable: true,
                        visible: true,
                        thStyle: { width: "5%" },
                        tdClass: 'fonteLinhasLeft'
                      },
                      {
                        key: 'aso.user.name',
                        label: 'Usuario',
                        sortable: true,
                        visible: true,
                        thStyle: { width: "10%" },
                        tdClass: 'fonteLinhasLeft'
                      }
                    ],  
            fields2: [
         
              {
                key: 'id',
                label: 'id',
                visible: false,
                sortable: true,
                thStyle: { width: "6%" },
                tdClass: 'fonteLinhasLeft'
              },
              {
                key: 'codigoaso',
                label: 'ASO EXCLUIDA',
                visible: true,
                sortable: true,
                thStyle: { width: "5%" },
                tdClass: 'fonteLinhasLeft'

              },
              {
                key: 'empresa_id',
                label: 'Empresa',
                visible: true,
                sortable: true,
                thStyle: { width: "20%" },
                tdClass: 'fonteLinhasLeft'

              },
              {
                key: 'paciente_id',
                label: 'Paciente',
                visible: true,
                sortable: true,
                thStyle: { width: "20%" },
                tdClass: 'fonteLinhasLeft'
              },
              
              {
                key: 'user_exclusao',
                label: 'Usuario',
                visible: true,
                sortable: true,
                thStyle: { width: "10%" },
                tdClass: 'fonteLinhasLeft'

              },
              {
                key: 'motivo',
                label: 'Motivo',
                visible: true,
                sortable: true,
                thStyle: { width: "15%" },
                tdClass: 'fonteLinhasLeft'
              },
              {
                key: 'data_exclusao',
                label: 'Data',
                sortable: true,
                visible: true,
                thStyle: { width: "8%" },
                tdClass: 'fonteLinhasLeft'
              },
             
            ],

            fields3: [

              {
                key: 'id',
                label: 'id',
                visible: false,
                sortable: true,
                thStyle: { width: "6%" },
                tdClass: 'fonteLinhasLeft'
              },
              {
                key: 'aso_id',
                label: 'ASO EDITADA',
                visible: true,
                sortable: true,
                thStyle: { width: "5%" },
                tdClass: 'fonteLinhasLeft'

              },
              {
                key: 'descricao_alteracao',
                label: 'Edicao',
                visible: true,
                sortable: true,
                thStyle: { width: "10%" },
                tdClass: 'fonteLinhasLeft'

              },
              {
                key: 'idempresa_novo',
                label: 'Empresa',
                visible: true,
                sortable: true,
                thStyle: { width: "20%" },
                tdClass: 'fonteLinhasLeft'

              },
              {
                key: 'motivo',
                label: 'Motivo',
                visible: true,
                sortable: true,
                thStyle: { width: "20%" },
                tdClass: 'fonteLinhasLeft'

              },

              {
                key: 'idusuario',
                label: 'Usuario',
                visible: true,
                sortable: true,
                thStyle: { width: "10%" },
                tdClass: 'fonteLinhasLeft'

              },
          
              {
                key: 'data_alteracao',
                label: 'Data',
                sortable: true,
                visible: true,
                thStyle: { width: "10%" },
                tdClass: 'fonteLinhasLeft'
              },

            ],

           empresasfora:[
                           { "id":"sim", "nome":"sim" }, 
                          { "id":"nao", "nome": "não" }
                          ],  
           empresas: [],
           listUsers: '',
           listTipoPagamento: '',
           dadosAso:[],
           dadosAsoExcluidas: [],
           dadosAsoEditadas:[],
           totalExames:0,
           nomeEmpresa:'',
           tipoPagamento: '',
           usuarioPesquisa:'',
           periodoRelatorio: '',
           tableRelatorioRecepcao:{},
           formdata:{},
           sessaoModal:'print',
           modalShow: false,
           editedIndex: -1,
           empresafora:''
                           
                        }
                      },
       
       mounted(){
        
        this.buscaEmpresas(),
        this.buscaUsers(),
        this.buscaTipoPagamento(),
             setTimeout(() =>{// aguarda com spinner antes da pesquisa aparecer na pesquisa inicial
                  this.isBusy = false
                  }, 2.0*2000)
        },
        computed:{

          visibleFields() {
                    return this.fields.filter(field => field.visible)
              },

          visibleFields2() {
                    return this.fields2.filter(field => field.visible)
          },
          visibleFields3() {
            return this.fields3.filter(field => field.visible)
          },
           rows() {
                    return  this.dadosAso.length
                  },
        },
        methods: {

                print() {
                          const modal = document.getElementById(this.sessaoModal)
                          const cloned = modal.cloneNode(true)
                          var section = document.getElementById('print')  

                          if (!section) {

                            section = document.createElement("div")
                            section.id = "print"
                            document.body.appendChild(section)

                          }

                          section.innerHTML = "";
                          section.appendChild(cloned);
                          window.print();
                },
         

              async ActionFindAsos(datainicio,datafim,tipopagamento,user,empresa) {

                if(this.empresafora==''){
                  this.empresafora = 0
                }
            
                var list_Dados = [];
                this.periodoRelatorio = 'Período: ' + datainicio + ' a ' + datafim;

                datainicio = this.formatarDataParaBanco(datainicio);
                datafim = this.formatarDataParaBanco(datafim);

                if(empresa){ 
                   await http.get('empresa/pesquisaempresaid/'+empresa).then(respEmpresa => {
                       this.nomeEmpresa= 'Empresa: '+respEmpresa.data.nome;
                  })
                }

                if(tipopagamento){
                  await http.get('tipopagamento/' + tipopagamento).then(respTipo => {
                    this.tipoPagamento = 'Tipo Pagamento: ' + respTipo.data.descricao;
                  })
                }          
                  
                if(user){
                    await http.get('user/' + user).then(respUser => {
                          this.usuarioPesquisa = 'Usuario: '+respUser.data.name;
                        })
                      }
                      

               if(!tipopagamento){
                       tipopagamento = 0
                  }

               if (!user) {
                       user = 0
                    }

               if (!empresa) {
                      empresa = 0
                      }
                      

              await http.get('examesaso/listexames/'+datainicio+'/'+datafim+'/'+tipopagamento+'/'+user+'/'+empresa+'/'+this.empresafora).then(resp => {
        
                if(resp){

                 var dados = 0;

                     (resp.data).forEach(function (item){//tratando as datas 

                        //SOMA OS EXAMES
                        dados += Number(item.valorexame);
                        item.aso.empresa.nome= item.aso.empresa.nome+"-"+ item.aso.empresa.cnpj

                                 if (tipopagamento !== 0) {
                                    if (item.aso.tipopagamento_id == tipopagamento){
                                      item.aso.created_at = moment(item.aso.created_at).format("DD/MM/yyyy")
                                      list_Dados.push(item);
                                    }
                                  }

                                 else if (empresa !== 0) {
                                        if (item.aso.empresa_id == empresa) {
                                          item.aso.created_at = moment(item.aso.created_at).format("DD/MM/yyyy")
                                          list_Dados.push(item);
                                        }
                                   }

                                  else if (user !== 0) {
                                        if (item.aso.user_id == user){
                                          item.aso.created_at = moment(item.aso.created_at).format("DD/MM/yyyy")
                                          list_Dados.push(item);
                                        }
                                   }

                                    else{
                                        item.aso.created_at = moment(item.aso.created_at).format("DD/MM/yyyy")
                                        list_Dados.push(item);
                                        }
                              })
                            }

                             this.totalExames = 'Total Exames: R$ '+this.convertNumberToReal(dados)
                             this.dadosAso = list_Dados

                             //busca as asos excluidas
                            // this.pesquisaAsoExcluidas(datainicio, datafim)
                         //    this.pesquisaAsoEditadas(datainicio, datafim)
                

                            })
                        
                    },

              async buscaEmpresas() {
                await http.get('/empresa').then(resp => {
                          for (let x in resp.data) {
                            resp.data[x].nome = '' + resp.data[x].nome + ' - ' + resp.data[x].cnpj + '';
                          }

                  this.empresas = resp.data
                })
              },

             async buscaTipoPagamento() {
                     await http.get('/tipopagamento').then(resp => {
                            this.listTipoPagamento = resp.data
                        })
            },

            async buscaUsers() {

                  await http.get('/user').then(resp => {
                    this.listUsers = resp.data
                  })
                },

            download: function () {
              const data = XLSX.utils.json_to_sheet(this.dadosAso)
              const wb = XLSX.utils.book_new()
              XLSX.utils.book_append_sheet(wb, data, 'data')
              XLSX.writeFile(wb, 'table_relatorios.xlsx')
            },

            createItem() {

                         //GERA UMA STRING ALEATORIA PARA O RELATORIO 
                        var gerarString = this.formdata.datainicio + this.formdata.datafim + this.formdata.tipopagamento_id + this.formdata.user_id + this.formdata.empresa_id;
                        this.sessaoModal= this.geraStringAleatoria(20,gerarString)


                        this.ActionFindAsos(this.formdata.datainicio,this.formdata.datafim,this.formdata.tipopagamento_id, this.formdata.user_id, this.formdata.empresa_id) 
                        this.modalShow = true;
                        this.editedIndex = -1;
                        
                    },

            close() {
                       this.modalShow = false;
                       setTimeout(() => {
                         this.formdata = {}
                       
                         this.editedIndex = -1;
                                        }, 300);
                     },


            geraStringAleatoria(tamanho, caracteres) {
                          
                          var stringAleatoria = '';
                          for (var i = 0; i < tamanho; i++) {
                            stringAleatoria += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
                          }
                      
                          return stringAleatoria;
                    },


             mascaraCnpj(value) {
                            var mascara = (`${value}`).replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
                            return mascara;
                  },

            formatarData: function (value) {
                       if (value === null) { return null }
                      return new Date(value).toLocaleString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit', hour:'2-digit', minute:'2-digit', second:'2-digit' })
                  },
                  
             resetInfoModal() {
                          this.infoModal.title = ''
                          this.infoModal.content = ''
                  }

          }
  }
</script>
<style >
.fonteLinhas {
   font-size:10px;
   text-align: center
}
.fonteLinhasLeft {
   font-size:10px;

}
.select-selected {
  border-color: black;
  border: 6px solid;
}

.select-selected.select-arrow-active:after {
  border-color: black;
  top: 7px;
}
.select-items div,
.select-selected {
  color: black;
  padding: 8px 16px;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.select-items {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

.retiraBorda {
    outline: none;
}

.select-hide {
  display: none;
}

.pesquisa_select{

  position: relative;
  margin-top: 20px;

}


.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
@mixin flex-center($columns: false) {
  display: flex;
  align-items: center;
  justify-content: center;
  @if $columns {
    flex-direction: column;
  }
}


@media screen {
  #print {
    display: none;
   }
}

@media print {
 body * {
  visibility:hidden;
  }
  #print, #print * {
    visibility:visible;
  }
  #print {
    position:absolute;
    left:0;
    top:0;
  }
}

</style>